import Swiper from "swiper";
import {Navigation, Pagination} from "swiper/modules";
import 'swiper/swiper-bundle.css';

export const MobileOnlySlider = () => {
    const eventHandler = (swiper: Swiper) => {
        // Disable the slider when the window width is less than or equal to 960
        if ( window.innerWidth > 1024) {
            swiper.disable()
            swiper.el.classList.add('-non-slider')
        } else {
            swiper.enable()
            swiper.el.classList.remove('-non-slider')
        }
    }
    new Swiper('.mobile-only-slider', {
        loop: true,
        autoplay: {
            delay: 2000,
        },
        pagination: {
            el: '.swiper-pagination',
        },
        modules: [Navigation],
        navigation: {
            nextEl: '.arrow.next',
            prevEl: '.arrow.prev',
        },
        on: {
            init: function (swiper: Swiper) {
                eventHandler(swiper);
            },
            resize: function (swiper: Swiper) {
                eventHandler(swiper);
            },
        }
    });
}