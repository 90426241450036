import Swiper from "swiper";
import {Navigation, EffectFade} from "swiper/modules";
import 'swiper/swiper-bundle.css';

export const VerhalenSlider = () => {

    const firstSwiper = new Swiper('.verhalenSection .first-swiper', {
        autoHeight: true,
        loop: true,
        breakpoints: {
            1024: {
                slidesPerView: 2,
            },
        },
        modules: [Navigation],
        speed: 1000,
        navigation: {
            nextEl: '.arrow.next',
            prevEl: '.arrow.prev',
        },
        spaceBetween: 0,
    });

    const secondSwiper = new Swiper('.verhalenSection .second-swiper', {
        autoHeight: true,
        loop: true,
        slidesPerView: 1,
        modules: [EffectFade],
        effect: "fade",

    });

    firstSwiper.on('slideChangeTransitionEnd', function() {
        let index_currentSlide = firstSwiper.realIndex;
        secondSwiper.slideTo(index_currentSlide, 1000, false);

        let paging = document.querySelector('.verhalenSection .pagingInfo');

        if (paging) {
            let pageNumber = index_currentSlide + 1;
            let totalSlides = firstSwiper.slides.length;

            let formattedPageNumber = "000000000" + pageNumber;
            formattedPageNumber = formattedPageNumber.substr(formattedPageNumber.length - 2);

            let formattedTotalSlides = "000000000" + totalSlides;
            formattedTotalSlides = formattedTotalSlides.substr(formattedTotalSlides.length - 2);

            paging.innerHTML = formattedPageNumber + ' / ' + formattedTotalSlides;
        }
    });
}