import '@css/app.scss'

import {VerhalenSlider} from "./components/VerhalenSlider";
import {Slider} from "./components/Slider";
import {MobileOnlySlider} from "./components/MobileOnlySlider";

$( document ).ready(function() {
    VerhalenSlider();
    Slider();
    MobileOnlySlider();
});