import Swiper from "swiper";
import {Navigation, Pagination} from "swiper/modules";
import 'swiper/swiper-bundle.css';

export const Slider = () => {

    new Swiper('.slider-swiper', {
        autoHeight: true,
        loop: false,
        breakpoints: {
            600: {
                slidesPerView: "auto",
                centeredSlides: true,
            },
            1024: {
                slidesPerView: 1,
            },
        },
        pagination: {
            el: ".swiper-pagination",
            type: "fraction",
            renderFraction: function (currentClass: string, totalClass: string) {
                return '<div class="swiper-pagination-wrapper">' +
                    '<span class="' + currentClass + '"></span>' +
                    ' / ' +
                    '<span class="' + totalClass + '"></span>' +
                '</div>';
            },
        },
        modules: [Navigation, Pagination],
        navigation: {
            nextEl: '.arrow.next',
            prevEl: '.arrow.prev',
        },
        spaceBetween: 20,
    });

}